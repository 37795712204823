export const content        = 'https://dlms.bdeducation.org.bd/';                   // live-content
// export const content     = 'http://localhost/cms/';                              // local
export const soltent        = 'https://solverdcms.bdeducation.org.bd/';
export const sdp            = 'https://absdp.bdeducation.org.bd/';
export const dlms           = 'https://dlms.bdeducation.org.bd/';                   // live-content
// export const dlms        = 'http://localhost/cms/';                              // local
export const questionBank   = 'https://qb.bdeducation.org.bd/';                     // live-content
// export const questionBank   = 'http://localhost/question_bank/';                 // local
export const peps           = 'https://solvercms.bdeducation.org.bd/';
export const storage        = 'digicam'                                             // localStorage var
export const local          = '/';
export const basename       = '/';
export const server         = 'https://dceimsv3.bdeducation.org.bd';                  // portal
//export const server         = 'https://eimsv2test.bdeducation.org.bd/';                  // portal
//export const server         = 'http://localhost:8080/eimsv2';                         // local